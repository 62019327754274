import React, { useEffect } from 'react';
import { P140NotFoundPage as P140NotFoundPageType } from '~/lib/data-contract';
import { useGtm } from '~/shared/utils';
import { DynamicBlockList } from '~/templates/blocks';
import { usePage } from '~/templates/pages';

export const P140NotFoundPage = () => {
    const { pageElements = [] } = usePage<P140NotFoundPageType>();
    const { track404Page, referer } = useGtm();
    useEffect(() => {
        track404Page(referer);
    }, [track404Page, referer]);
    return <DynamicBlockList elements={pageElements} />;
};
